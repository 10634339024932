.interested {
  padding: 80px 0;
  background: rgba(146, 137, 114, 0.1);

  &__wrap {
    display: flex;
    flex-wrap: wrap;
  }  

  &__width {
    &--50 {
      width: calc(50% - 20px);
      margin-right: 20px;
    }

    &--100 {
      width: calc(100% - 20px);
      margin-right: 20px;
    }
  }

  label {
    font-weight: bold;
  }

  input[type="text"] {
    width: 100%;
    border: none;
    height: 46px;
  }

  textarea {
    width: 100%;
    border: none;
    min-height: 200px;
  }

  input[type="submit"] {
    padding: 15px 50px;
    background: $color-blue;
    color: #fff;
    font-weight: bold;
  }
}