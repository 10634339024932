.objects {
  &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-left: -25px;
    }

  &__header {
    padding: 60px 0 30px 0;
    max-width: 800px;
    margin: auto;
  }

  &__filters {
    margin: auto;
    padding: 12px 0 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-top: 1px solid rgba(146,137,114,.2);

    span {
      font-weight: bold;
      margin-right: 25px;
      color: $color-blue;
    }

    img {
      margin-right: 10px;
    }

    label {
      background: $color-gray;
      padding: 10px 20px;
      cursor: pointer;
      margin-right: 10px;
      color: rgba(0, 26, 68, 0.5);
      transition: 150ms ease;
      border-radius: 3px;

      &:hover, &:focus { 
        opacity: .8;
        border-radius: 20px;
      }

      &.active {
        background: $color-blue;
        color: #fff;
      }


    }
  }

  &__meta {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    color: #fff;
    display: block;
    font-size: 12px;
    i {
      margin-right: 5px;
    }
  }
  

  &__items {
    background: $color-gray;
    padding: 80px 0 0px;

    &--no-background {
      background: none;
      padding: 0 0 20px;
    }

  }

  &__item {
    flex: 1;
    flex-basis: calc(25% - 25px);
    max-width: calc(25% - 25px);
    margin: 25px 0 25px 25px;
    position: relative;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    transition: all 200ms ease-in-out !important;
    border-bottom: 4px solid #FFF;
    overflow: hidden;
    border-radius: 5px;

    img {
      width: 100%;
    }

    h3 {
      font-size: 16px;
      color: $color-black;
      margin: 0;
      transition: all 200ms ease-in-out;
    }

    &:hover {
      transform: scale(1.05) !important; 
      border-bottom: 4px solid $color-brown;

      h3 {
        color: $color-brown;
      }
    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    background: $color-blue;
    padding: 5px 10px;
    z-index: 1;

    &s {
      display: flex;
    }

    span {
      color: #fff;
    }
  }

  &__image {
    height: 192px;
    width: 100%;
    background-size: cover;
    background-position: center;
    transition: 200ms all ease-in-out;
    filter: sepia(80%);

    &:hover {
			filter: sepia(0);
		}
  }

  &__title {
    background: #fff;
    padding: 15px;
    min-height: 54px;
    height: 100%;
  }
}

@media (max-width: $media-tablet) {
  .objects {
    &__header {
      padding: 30px 0;
    }

    &__items {
      padding: 30px 0;
    }
  
    &__item {
      flex-basis: calc(50% - 25px);
      max-width: calc(50% - 25px);

      &:hover {
        transform: none;
      }
    }

    &__title {
      padding: 15px;
    }

    &__buttonwrapper {
      display: block;
    }

    &__buttons {
      display: block;

      a {
        width: auto;
        margin: 10px 0;
      }
    }

    &__filters {
      position: relative;
      display: flex;
      height: 70px;
      padding: 0;
  
      span {
        cursor: pointer;
      }
    }

    &__labels {
      position: absolute;
      background: #fff;
      top: 100%;
      width: 100%;
      z-index: 2;
      box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
      display: none;

      label {
        background: none;
        padding: 0;
        margin: 0;
        display: block;
        padding: 15px;
        border: 1px solid $color-gray3;
      }

      &.open {
        display: block;
      }
    }
  }
}

@media (max-width: $media-mobile) {
  .objects {

    &__wrapper {
      margin-left: 0;
    }

    &__filters {
      height: 40px;
      align-items: center;
      display: flex;
      position: relative;
    }

    &__content {
      display: none;
    }

    &__item {
      flex-basis: 100%;
      max-width: 100%;
      margin: 0;
      margin-bottom: 25px;
    }    

    &__price, &__pricetype {
			display: none;
    }
    
  }    
}
