.post_header {
  background: $color-gray;
  position: relative;

  &__image {
    height: 310px;
    background-size: cover;
    z-index: 1;
    position: relative;
  }

  &:after {
    content: '';
    height: 80px;
    width: 100%;
    position: absolute;
    top: 0;
    background: #fff;
  }

  .breadcrumbs {
    background: none;
    padding: 20px 0;
  }
}