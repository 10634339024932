.object {
  &__info {
    padding: 80px 0;

    .top {
      margin-left: calc(33% + 22px);
      span {
        display: block;
        font-size: 40px;
        line-height: 40px;
        color: $color-brown;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
  }

  &__heading {
    display: block;
    padding: 0 15px;
  }

  &__images {
    width: calc(33% - 15px);
    margin-bottom: 40px;

    &--horizontal {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    &--horizontal .object {

      &__image {
        margin: 15px;
        flex-basis: calc(25% - 30px);
      }

      &__button {
        flex-basis: calc(25% - 30px);
        margin: 15px;
        align-items: center;
        display: flex;
        justify-content: center;
        background: #fff;
        color: $color-brown;
        box-shadow: 0 4px 25px rgba(0,0,0,.1);

        &Text {
          color: $color-brown;
        }
      }

    }

  }



  &__image {
    height: 228px;
    width: 100%;
    background-size: cover;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 150ms;
    border-radius: 5px;
    position: relative;
    
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background: #fff;
      display: flex;
      margin-left: auto;
      border-radius: 5px 0 0 0;
      background-image: url('/theme/assets/images/zoom.svg');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      background-position: center center;
    }

    &:hover, &:focus {
      opacity: .8;
      border-radius: 20px;
    }

    img {
      margin: auto;
    }
  }

  &__button {
    display: block;
    text-align: center;
    transition: 150ms ease;

    &:hover, &:focus {
      opacity: .8;
    }
  }

  &__hidden {
    display: none;
  }

  &__wrapper {
    display: flex;
  }

  &__description {
    width: calc(66% - 15px);
    margin: 0 auto;
    margin-right: 0;
  }

  &__interested {
    background: $color-lightbrown;
    padding: 60px 0;
  }

  &__container {
    width: 100%;
  }

}

@media (max-width: $media-tablet) {
  .object {
    &__info {
      .top {
        margin-left: 0;
      }
    }

    &__wrapper {
      flex-direction: column-reverse;
    }
  
    &__images {
      width: 100%;

      &--horizontal .object {

        &__image {
          flex-basis: calc(50% - 30px);
        }

        &__button {
          flex-basis: calc(50% - 30px);
        }
      }

    }

    &__description {
      width: 100%;
    }
  }
}

@media (max-width: $media-mobile) {
  .object {

    &__info {
      padding: 20px 0;
   
      .top {

        span {
          display: block;
          font-size: 25px; 
          line-height: 40px;
          color: $color-brown;
          font-weight: bold;
          margin-bottom: 20px;
        }

      }

    }

    &__images {
      &--horizontal .object {

        &__image {
          margin: 15px; 
          flex-basis: 100%;

          &:nth-of-type(1n+4) {
            display: none;
          }

        }

        &__button {
          flex-basis: 100%;
          box-shadow: none;
          background-color: $color-brown;

          &Text {
            color: #FFF;
          }
        }

      }
    }
    
  }
}