.links {
  padding: 80px 0;
  border-top: 1px solid $color-gray3;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    list-style: none;
    padding-bottom: 15px;
  }

  a {
    color: $color-blue;
    text-decoration: underline;
  }

  h3 {
    color: #001a44;
  }
}

.togglearrow {
  display: none;
}

@media (max-width: $media-tablet) {
  .links {
    border: none;
    padding: 0;

    &__wrapper {
      padding: 0;
      // max-width: 100%;
    }
  }

  .togglewrapper {
    border-top: 1px solid $color-brown-op;
    width: 100%;
    position: relative;
    padding-left: 15px;
  }

  .toggle {
    display: none;
    position: relative;
  }

  .togglearrow {
    position: absolute;
    right: 15px;
    top: 15px;
    transition: 300ms all ease-in-out;
    display: block;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}