.featured_image {
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__image {
    height: 570px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
  }

  &:after {
    content: '';
    height: 50%;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: $color-gray;
  }

  &__info {
    position: absolute;
    background: #fff;
    max-width: 800px;
    bottom: -30px;
    right: 30px;
    z-index: 2;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    padding: 25px;

    &:after {
      content: '';
      height: 4px;
      width: 80%;
      background: #928972;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    h1 {
      color: $color-brown;
    }

    a {
      font-weight: bold;
    }
  }
}

@media (max-width: $media-tablet) {
  .featured_image {

    .container {
      padding: 0;
    }
    
    &:after {
      height: 70%;
    }

    &__image {
      height: 235px;
    }

    &__info {
      position: relative;
      z-index: 2;
      right: 0;
      top: -20px;
      margin: 0 15px; 
    }
  }
}