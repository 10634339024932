.object_header {
  background: $color-gray;

  &__breadcrumb {
    padding-top: 40px;
    max-width: 1100px;
    margin: auto;
    padding-bottom: 40px;

    a {
      color: $color-gray2;
      font-weight: bold;
      font-size: 14px;
    }

    .next {
      margin: 0 10px;
    }
  }

  &__inner {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0 80px;
    max-width: 1100px;
    margin: auto;

    strong {
      color: $color-black;
      font-size: 18px;
    }
  }

  &__location {
    width: 50%
  }

  &__interested {
    width: 50%
  }

  &__data {
    width: 50%
  }

  &__list {
    list-style: none;
    padding: 0;

    &Item {
      padding-bottom: 20px;
      display: flex;
      align-items: center;
      line-height: 120%;
    }

    svg {
      width: 25px;
      height: 25px;
      margin-right: 15px;
    }

  }

}

@media (max-width: $media-tablet) {
  .object_header {
    &__location {
      width: 100%;
    }
  
    &__interested {
      width: 100%;
    }

    &__breadcrumb {
      display: none;
    }
  }

  @media (max-width: $media-mobile) {
    .object_header {
      &__data {
        width: 100%
      }
    }
  }
}
