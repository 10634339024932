.blogs {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: auto;
  }

  &__header {
    padding: 60px 0 30px 0;
    max-width: 800px;
    margin: auto;
  }

  hr {
    border-color: rgba(0, 0, 0, 0.1);
  }

  &__items {
    background: $color-gray;
    padding: 80px 0;
    min-height: 700px;
  }

  &__item {
    width: 100%;
    height: 180px;
    margin-bottom: 35px;
    position: relative;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    display: flex;

    img {
      width: 100%;
    }
  }

  &__image {
    width: 180px;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: 200ms all ease-in-out;
    filter: sepia(50%);
  }

  &__title {
    background: #fff;
    padding: 0 27px;
    width: calc(100% - 180px);
    position: relative;

    h3 {
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }

    .link {
      width: 40px;
      height: 40px;
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      background: $color-brown;

      img {
        margin: auto;
        width: auto;
      }
    }
  }

  &__navigate {
    display: flex;
    margin: 25px auto 0;

    ul {
      display: flex;
      padding: 0;
      margin: 0;

      li {
        list-style: none;
        margin: auto 8px;
      }
    }

    .arrow {
      background: $color-blue;
      height: 46px;
      width: 46px;
      display: flex;

      img {
        margin: auto;
      }

      &.left {
        transform: rotate(180deg);
        margin-right: 25px;
      }

      &.right {
        margin-left: 25px;
      }
    }
  }
}

@media (max-width: $media-mobile) {
  .blogs {
    &__header {
      padding: 30px 0;
    }
    &__items {
      padding: 30px 0;
    }

    &__item {
      display: block;
      height: 100%;
    }

    &__image {
      width: 100%;
      height: 180px;
    }

    &__title {
      width: 100%;
      min-height: 40px;
      padding: 5px 40px 5px 0;
      display: flex;

      h3 {
        margin: auto 15px;
        font-size: 14px;
      }

      p {
        display: none;
      }
    }
  }
}
