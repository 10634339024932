.recent_objects {
  padding: 35px 0;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -25px;
  }

  &__item {
    // width: calc(25% - 25px);
    width: 241px;
    margin: 0 25px 35px 0;
    position: relative;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 200ms ease-in-out; /* Safari */
    transition: all 200ms ease-in-out;

    img {
      width: 100%;
    }

    h3 {
      font-size: 16px;
      color: $color-black;
      margin: auto;
    }

    &:hover {
      transform: scaleY(1.03); 
    }
  }

  &__image {
    height: 192px;
    width: 100%;
    background-size: cover;
    background-position: center;
    transition: 200ms all ease-in-out;
    filter: sepia(50%);
  }

  &__spacing1 {
    width: calc(241px + 25px);
  }

  &__spacing2 {
    width: calc((3 * (241px + 25px)));
  }

  &__title {
    background: #fff;
    padding: 15px;
    min-height: 58px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;

    a {
      width: 241px;
      margin: 0 25px 35px 0;
      padding: 15px 0;
      text-align: center;
      transition: 150ms;

      &:hover {
        opacity: .8;
      }
    }
  }

  &__buttonwrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px;
    
    &--middle {
      justify-content: center;  
    }

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }

  }
}
@media (max-width: $media-desktop) {
  .recent_objects {
    &__spacing1 {
      width: auto;
    }

    &__spacing2 {
      width: auto;
    }
  }
}

@media (min-width: $media-mobile) and (max-width: $media-tablet) {
  .recent_objects {
    &__spacing {
      display: none;
    }

    &__buttons {
      a {
        // width: calc(50% - 20px);
        margin: 25px 20px 25px 0;
      }
    }
  }
}

@media (max-width: $media-mobile) {
  .recent_objects {
    width: calc(100% + 30px);
    margin-left: -15px;

    &__wrapper {
      margin-left: 0;      
    }

    &__item {
      width: 100%;
      margin: 25px;

      &:hover {
        transform: none;
      }
    }
    
    .slick-slide {
      margin: 0px 10px;
    }

    &__buttonwrapper {
      display: block;
    }

    &__buttons {
      display: block;
      margin-top: 45px;

      a {
        width: auto;
        margin: 10px 0;
      }
    }
  }
}