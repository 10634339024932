.offer {
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	&__header {
		padding: 60px 0 30px 0;
		max-width: 800px;
		margin: auto;
	}

	&__filters {
		max-width: 800px;
		margin: auto;
		padding: 20px 0;


		span {
			font-weight: bold;
			margin-right: 25px;
			color: $color-blue;

			img {
				margin-right: 10px;
			}
		}

		label {
			background: $color-gray;
			padding: 10px 20px;
			cursor: pointer;
			margin-right: 10px;

			&.active {
				background: $color-blue;
				color: #fff;
			}
		}
	}

	&__items {
		background: $color-gray;
		padding: 80px 0 40px;
		min-height: 40vh;
	}

	&__item {
		width: 100%;
		margin-bottom: 30px;
		position: relative;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
		display: flex;
		overflow: hidden;
		border-radius: 10px;
		transition: 150ms ease;

		img {
			width: 100%;
		}

		&:hover,
		&:focus {
			transform: scale(1.05) !important;
		}
	}

	&__label {
		background: $color-blue;
		padding: 2px 10px;
		display: inline-block;
		margin-bottom: 15px;
		border-radius: 5px;
		font-size: 12px;
		font-weight: 800;
		color: #fff;
		margin-right: 10px;

		&Container {
			display: none;
		}

	}

	&__image {
		height: 240px;
		width: 45%;
		background-size: cover;
		background-position: center;
		transition: 200ms all ease-in-out;
		filter: sepia(50%);

		&:hover {
			filter: sepia(0);
		}
	}

	&__title {
		background: #fff;
		padding: 17px 60px 0px 35px;
		width: 55%;
		position: relative;

		h3 {
			font-size: 20px;
			color: #000;
			font-weight: bold;
			margin-bottom: 15px;
		}

		.link {
			width: 40px;
			min-height: 40px;
			display: flex;
			position: absolute;
			bottom: 0;
			right: 0;
			background: $color-brown;

			img {
				margin: auto;
				width: auto;
			}
		}
	}

	&__navigate {
		display: flex;
		margin: 25px auto 0;

		ul {
			display: flex;
			padding: 0;
			margin: 0;

			li {
				list-style: none;
				margin: auto 8px;
			}
		}

		.arrow {
			background: $color-blue;
			height: 46px;
			width: 46px;
			display: flex;

			img {
				margin: auto;
			}

			&.left {
				transform: rotate(180deg);
				margin-right: 25px;
			}

			&.right {
				margin-left: 25px;
			}
		}
	}
}

@media (max-width: $media-tablet) {
	.offer {
		&__header {
			padding: 30px 0;
		}
		&__items {
			padding: 30px 0;
		}

		&__item {
			display: block; 
		}

		&__image {
			width: 100%;
		}

		.objects {
			&__price, &__pricetype {
				display: none;
			}
		}

		&__label {
			display: none;
			margin-right: 5px;

			&Container {
				display: inline;
				position: absolute;
				bottom: 0;
				padding-left: 10px;
				
				& .objects {	
					display: block;

					&__price, &__pricetype {
						display: inline;
					}

				}
			}

			&--mobile {
				margin-bottom: 10px;
				display: inline-block;
			}

		}

		&__title {
			width: 100%;
			min-height: 40px;
			padding: 0;
			display: flex;

			h3 {
				margin: auto 15px;
				font-size: 14px;
				line-height: 20px;
  			padding: 10px 0;
			}

			p {
				display: none;
			}
		}

	}
}

@media (max-width: $media-mobile) {
	.offer {

	}

}