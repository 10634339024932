.breadcrumbs {
  background: $color-lightbrown;
  padding: 9px 0;
  display: flex;
  align-items: center;

  a {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .next {
    // margin: auto 10px;
    margin: 0 10px;
  }
}

@media (max-width: $media-tablet) {
  .breadcrumbs {
    display: none;
  }
}