.card {
  width: calc(33% - 40px);
  margin: 20px 40px 0 0;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  padding: 30px 25px;
  border-bottom: 4px solid $color-brown;
  background: #fff;
  position: relative;
  overflow: hidden;
  transition: 150ms ease;

  &:hover {
    transform: scale(1.05) !important;
    box-shadow: 0 -10px 35px rgba(0,0,0,.1)
  }

  &:first-child {
    margin-left: 0;
  }

  span {
    margin-top: 25px;
    font-weight: bold;
    display: block;
    transition: 150ms ease;

    &:hover {
      opacity: .8;
    }
  }
}

@media (max-width: $media-tablet) {
  .card {
    width: 100%;
    margin: 10px 0;
    padding: 40px 40px 20px 60px;

    img {
      position: absolute;
      left: -30px;
      top: 45px;
      width: 63px;
    }
  }
}
