.footer {
  background: $color-blue;
  padding: 80px 0;
  position: relative;

  &__wrapper {
    display: flex;
  }
 
  &__links {
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    position: relative;
  }

  &__bottom {
    margin-top: 20px;
  }

  &__logoleft {
    margin: auto;
    margin-left: 0;
    z-index: 1;
  }

  &__logoright {
    margin: auto;
    margin-right: 0;
    z-index: 1;
    display: flex;
    align-items: center;    
  }

  &__background {
    background-size: cover;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 75%;
  }

  &__shamefullselfpromotion {
    color: #FFF;
    font-size: 14px;
    margin-right: 10px;
  }

}

@media (max-width: $media-tablet) {
  .footer {
    &__wrapper {
      flex-direction: column;
    }

    &__logoleft {
      margin: auto;
    }

    &__logoright {
      margin: auto;
      margin-top: 25px;
    }

    &__links {
      .block__column {
        width: 50%;
      }
    }
  }
}

@media (max-width: $media-mobile) {
  .footer {
    &__links {
      .block__column {
        width: 100%;
      }
    }
  }
}