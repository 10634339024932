.block {
  &__colum-title {
    color: #fff;
  }

  &__column {
    width: 25%;

    p {
      color: #fff;
    }
  
    ul {
      padding: 0;

      li {
        list-style: none;
        padding-bottom: 15px;

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }

    &.width33 {
      width: 33%;

      ul li a {
        color: $color-blue;
      }

      h3 {
        color: $color-blue;
      }
    }
    &.width25 {
      width: 25%;
    }
  }
}

@media (max-width: $media-tablet) {
  .block {
    &__column {
      width: 100%;

      &.width33 {
        width: 100%;
      }
    }
  }
}