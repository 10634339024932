.post_title {
  padding-top: 40px;

  &__author {
    display: flex;

    .image {
      background-size: cover;
      background-position: center;
      border-radius: 100%;
      height: 45px;
      width: 45px;
      margin: auto 0;
    }

    .info {
      margin: auto 0;
      margin-left: 20px;

      span.author {
        display: block;
        font-weight: bold;
      }

      span.date {
        font-size: 14px;
        color: $color-gray2;
      }
    }
  }
}