@import "header.scss";
@import "footer.scss";

.page {
  &__title { 
    margin: 60px 0 30px;

    h1 {
      margin: 0;
    }
  }
}
