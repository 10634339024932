.contact {
	&__wrap {
		display: flex;
		flex-wrap: wrap;
	}

	&__width {
		&--50 {
			width: calc(50% - 20px);
			margin-right: 20px;
		}

		&--100 {
			width: calc(100% - 20px);
			margin-right: 20px;
		}
	}

	label {
		font-weight: bold;
	}

	input[type="text"] {
		width: 100%;
		border: none;
		height: 46px;
		padding: 5px 10px;
		border: 2px solid rgba(0, 26, 68, 0.15);
		border-radius: 3px;
		&:focus,
		&:hover {
			border: 2px solid $color-brown;
		}
	}

	textarea {
		width: 100%;
		padding: 5px 10px;
		border: none;
		min-height: 200px;
	}

	&.grijs {
		padding-bottom: 80px;

		textarea,
		input[type="text"] {
			background: $color-gray;
			border: 2px solid rgba(0, 26, 68, 0.15);
			border-radius: 3px;
		}
	}

	&.wit {
		textarea,
		input[type="text"] {
			background: #fff;
			border: 2px solid rgba(0, 26, 68, 0.15);
			border-radius: 3px;
		}
	}

	input[type="submit"] {
		padding: 15px 50px;
		background: $color-blue;
		color: #fff;
		font-weight: bold;
		border: none;
		cursor: pointer;
		transition: 150ms ease;
		border-radius: 5px;

		&:hover,
		&:focus {
			opacity: 0.8;
			border-radius: 20px;
		}
	}

	.checkbox {
		display: block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.checkbox input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 25px;
		width: 25px;
		background-color: #eee;
		transition: 150ms ease;
		border: 2px solid rgba(0, 26, 68, 0.15);
	}

	/* On mouse-over, add a grey background color */
	.checkbox:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		opacity: 0;
		transition: 150ms ease;
	}

	/* Show the checkmark when checked */
	.checkbox input:checked ~ .checkmark:after {
		opacity: 1;
	}

	/* Style the checkmark/indicator */
	.checkbox .checkmark:after {
		left: 5px;
		top: 5px;
		width: 15px;
		height: 15px;
		background: $color-blue;
	}

	// p.checkbox {
	//   display: flex;
	//   flex-direction: row-reverse;
	//   position: relative;
	//   -webkit-user-select: none;
	//   -moz-user-select: none;
	//   -ms-user-select: none;
	//   user-select: none;
	//   cursor: pointer;

	//   label {
	//     margin: auto;
	//     margin-left: 10px;
	//   }

	// input {
	//   position: absolute;
	//   opacity: 0;
	//   cursor: pointer;
	//   height: 0;
	//   width: 0;
	//   margin: auto 0;
	// }

	// .checkmark {
	//   position: absolute;
	//   top: 0;
	//   left: 0;
	//   height: 25px;
	//   width: 25px;
	//   background-color: #eee;
	// }

	// input:checked ~ .checkmark {
	//   background-color: $color-blue;
	// }

	// input:hover input ~ .checkmark {
	//   background-color: #ccc;
	// }

	// }
}

@media (max-width: $media-tablet) {
	.contact {
		&__width {
			&--50 {
				width: 100%;
				margin-right: 0px;
			}

			&--100 {
				width: 100%;
				margin-right: 0px;
			}
		}
	}
}
