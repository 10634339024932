.banner {
  height: 60vh;
  background-size: cover;
  background-position: center;
}

@media (max-width: $media-tablet) {
  .banner {
    height: 235px; 
  }
}
