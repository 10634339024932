.partners {
  margin-bottom: 80px;

  h2 {
    margin-bottom: 50px;
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    margin: auto 0;
    width: 25%;
    padding: 15px;
    display: flex;

    img {
      max-width: 100%;
      height: auto;
      margin: auto;
    }
  }

  &__link {
    text-align: center;
    margin-top: 50px;
  }
}

@media (max-width: $media-mobile) {
  .partners {
    &__image {
      width: 100%;
      img {
        max-width: 100%;
        height: auto;
        margin: auto;
      }
    }
  }
}