* {
  box-sizing: border-box;
}

body {
  font-family: $font-family-base;
  color: #484848;
  line-height: 27px;
  font-size: 16px;
  border-top: 10px solid $color-brown;
}

h1 {
  font-size: 30px;
  line-height: 30px;
  color: $color-brown;
  margin: 0 0 15px;
}

h2 {
  font-size: 25px;
  line-height: 25px;
  color: $color-brown;
}

h3 {
  font-size: 20px;
  color: $color-blue;
}

p {
  margin: -5px 0 20px;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

a {
  color: $color-blue;
  text-decoration: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.spacing1 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.d-flex{
  display: flex;
  flex-wrap: wrap;
}

.after {
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    height: 30%;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #fff;
    z-index: -1;
  }
}

.slick-dots {
  margin-bottom: 25px;
}

.slick-dots li {
  margin: 0 !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

@media (max-width: $media-desktop) {
  .container {
    max-width: 992px;
  }
}

@media (max-width: $media-tablet) {
  .container {
    max-width: 768px;
  }

  .md-nopadding {
    padding: 0;
  }

  .spacing1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 25px;
  }

  body {
    padding-top: 50px;
  }
}

@media (max-width: $media-mobile) {
  h1,
  h2,
  h3 {
    color: $color-blue;
  }

  body {
    border-top: 0;
  }
}
