.tab {

  &s {
    position: relative;
    background: #fff;
    max-width: 800px;
    box-shadow: 0 4px 25px rgba(0,0,0,.1);
    padding: 25px;

    &::after {
      content: '';
      height: 4px;
      width: 80%;
      background: #928972;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .floorplans .object__image {
    height: auto;
  }

  .floorplans .object__image img {
    height: auto;
    max-width: 100%;
  }


  &__item {
    list-style: none;
    display: inline-block;
    line-height: 40px;

    &s {
      margin: 0 0 41px;
      padding: 0;
    }
  }

&__link {
  background: #f4f5f6;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  color: rgba(0,26,68,.5);
  transition: 150ms ease;
  border-radius: 3px;

  &:hover, &:focus {
    opacity: .8;
    border-radius: 20px;
  }

  &.is-active {
    background: #001a44;
    color: #fff;
  }
}

}