.team_member {
  text-align: center;
  margin-right: 15px;
  width: calc(25% - 15px);

  &__image {
    width: 216px;
    height: 216px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
    margin: auto;
  }

  &__name {
    h3 {
      font-size: 16px;
      color: #000;
    }
  }
}

@media (max-width: $media-tablet) {
  .team_member {
    width: calc(50% - 15px);

    &__image {
      width: 140px;
      height: 140px;
    }
  }
}

// @media (max-width: $media-mobile) {
//   .team_member {
//     width: 100%;
//     margin: auto;
//     margin-bottom: 25px;
//   }
// }