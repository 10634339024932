.table {
  margin: 40px 0;

  h3 {
    margin: 0;
    color: $color-blue;
    font-size: 25px;
    margin-bottom: 25px;
  }

  table {
    width: 100%;
    border-collapse: collapse; 

    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    td {
      display: inline-block;
      margin: 10px 0;
    }
  }
  &__cell {
    &:first-child {
      font-weight: bold;
      color: $color-black;
      width: 200px;
    }
  }
}