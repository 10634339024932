.button {
  &--primary {
    display: inline-block;
    padding: 15px 30px;
    background: $color-blue;
    color: #fff;
    font-weight: bold;
    transition: 150ms ease;
    border-radius: 5px;
    margin-bottom: 10px;    

    &:hover, &:focus {
      opacity: .8;
      border-radius: 20px;
    }
  }

  &--secondary {
    padding: 15px 30px;
    background: $color-brown;
    color: #fff;
    font-weight: bold;
    transition: 150ms ease;
    border-radius: 5px;
    margin-bottom: 10px;

    &:hover, &:focus {
      opacity: .8;
      border-radius: 20px;
    }
  }
}

@media (max-width: $media-tablet) {
  .button {
    &--primary {
      display: block;
      text-align: center;
    }
  }
}