.header {
  &__top {
    background-color: $color-brown;
    border-bottom: 1px solid $color-brown-op;
  }

  &__login {
    height: 45px;
    display: flex;

    a {
      margin: auto;
      margin-right: 0;
      text-decoration: none;
      color: #FFF;
      font-weight: 700;
      display: flex;
      transition: 150ms ease;
      opacity: 1;
      
      svg {
        fill: #FFF;
      }

      &:hover, &:focus {
        opacity: .8;
      }
    }

    img {
      margin-right: 10px;
    }
  }
  &__bottomwrapper {
    height: 100px;
    display: flex;
    transition: 250ms ease;
    &:hover nav li {
      opacity: .8;
      &:hover {
        opacity: 1;
      }
    }
    li:hover:not(:hover) {
      background: red;
    }
  }

  &__logo {
    margin: auto;
    margin-left: 0;

    img {
      position: relative;
      width: 120%;
      top: 3px;
    }
  }

  nav {
    margin: auto;
    margin-right: 0;

    li {
      display: inline;
      margin-left: 55px;

      a {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  &__mobile {
    display: none;
  }

  &__mmenu {
    display: none;
  }
}

@media (max-width: $media-tablet) {
  .header {
    &__desktop {
      display: none;
    }

    &__mobile {
      display: flex;
      height: 50px;
      border-top: 4px solid $color-brown;
      box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
      position: fixed;
      width: 100%;
      background: #fff;
      z-index: 10;
      top: 0;

      .trigger {
        margin: auto 0;
        margin-left: 15px;
        cursor: pointer;

        .bars {
          .bar {
            height: 3px;
            width: 20px;
            margin-bottom: 3px;
            display: block;
            background-color: $color-blue;
            -webkit-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
          }
        }

      }

      .logo {
        margin: auto;
        
        a {
          display: flex;
        }

        img {
          height: 36px;
        }
      }

      .login {
        width: 60px;
        height: 100%;
        background: $color-brown;
        display: flex;

        a {
          margin: auto;
          display: flex;
        }

      }
    }

    &__mmenu {
      position: absolute;
      top: 45px;
      width: 100%;
      background: rgb(255, 255, 255);
      z-index: 1;
      box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);

      li {
        list-style: none;
        border-bottom: 1px solid #dadada;

        &:first-child {
          border-top: 1px solid #dadada;
        }

        &:last-child {
          border: none;
        }

        a {
          width: 100%;
          padding: 15px;
          display: block;
        }

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}

.menu-open {
  .trigger {
    .bar {
      &:first-child {
        opacity: 0;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        margin-top: -6px;
        transform: rotate(-45deg);
      }
    }
  }
}


// .menu-open .header__trigger .bar:first-child {
//   -webkit-transform: rotate(-45deg);
//   -o-transform: rotate(-45deg);
//   transform: rotate(-45deg);
// }

// .menu-open .header__trigger .bar:nth-child(2) {
// -webkit-transform: rotate(45deg);
// -o-transform: rotate(45deg);
// transform: rotate(45deg);
// margin-top: -9px;
// }

// .menu-open .header__trigger .bar:nth-child(3) {
// opacity: 0;
// }